export function initUserPoint(form = {}) {
    return {
        ...(form || {}),

        _user: form?._user || null,
        _order: form?._order || null,
        _participant: form?._participant || null,

        amount: form?.amount || null,
        remain: form?.remain || null,
        remark: form?.remark || null,

        createdAt: form?.createdAt || null,
    };
}
