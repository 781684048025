export function initCommonSetting(form = {}) {
    return {
        ...(form || {}),

        point: {
            ...(form?.point || {}),

            useLimitYearly: {
                ...(form?.point?.useLimitYearly || {}),

                enabled: form?.point?.useLimitYearly?.enabled ?? false,
                value: form?.point?.useLimitYearly?.value || null,
            },
        },
    };
}
